import WebsiteList from "@/views/website/WebsiteList"
import InternalCommunications from "@/views/internal-communications/InternalCommunications"
import EmployeeList from "@/views/employee/EmployeeList.vue"
import Dashboard from "@/views/dashboard/Dashboard.vue"
import WordpressAccountList from "@/views/wordpress/WordpressAccountList.vue"
export default[
    {
        path:'/seo-monitor/websites',
        name:"WebsiteList",
        component:WebsiteList,
        meta: { requiresAuth: true , dependOn:"serprobot"},
    },
    {
        
        path:'/seo-monitor/internal-communications',
        name:"InternalCommunications",
        component:InternalCommunications,
        meta: { requiresAuth: true ,dependOn:"internal_communication"},
    },
    {
        
        path:'/seo-monitor/employee',
        name:"Employee",
        component:EmployeeList,
        meta: { requiresAuth: true,dependOn:"serprobot" },
    },
    {
        
        path:'/seo-monitor/dashboard',
        name:"Dashboard",
        component:Dashboard,
        meta: { requiresAuth: true,dependOn:"google_rank_report" },
    },
    {
        
        path:'/seo-monitor/wordpress',
        name:"Wordpress",
        component:WordpressAccountList,
        meta: { requiresAuth: true,dependOn:"auto_wordpress" },
    }
] 
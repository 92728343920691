<template>
  <div class="recognition-page-container">
    <div class="recognition-history">
      <div class="table-action">
        <a-input-search v-if="false" placeholder="Tìm kiếm theo url website hoặc theo từ khóa" style="width: 400px"
          :enter-button="true" @search="initvalue" />
        <div></div>
        <div class="action-list"><a-button @click="showModal">Thêm</a-button>

        </div>
      </div>
      <a-modal :width="700" title="Thêm Nhân Viên" v-model:visible="isModalVisible" @ok="handleOk"
        @cancel="handleCancel">
        <AddEmployeeForm @close="handleCancel" @refresh="initvalue" />
      </a-modal>

      <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="handleTableChange" bordered
        size="middle" :scroll="overflow">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'keywords'">
            <a-collapse v-model:activeKey="record.activeKey" :bordered="false" style="background: transparent">
              <a-collapse-panel :key="1" :header="` ${record.keywords.length} từ khóa`">
                <div class="keyword-list">
                  <a-tag :color="'green'" v-for="(keyword, index) in record.keywords" :key="index">
                    {{ keyword }}
                  </a-tag>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import {
  DeleteTwoTone,
  FolderViewOutlined,
  SmileOutlined,
} from "@ant-design/icons-vue";
import { mask, unMask } from "@/utils";
import moment from "moment";
import { httpClient } from "@/apis/httpclient";
import { notification } from 'ant-design-vue';
import AddEmployeeForm from "./AddEmployeeForm.vue";

export default {
  components: {
    DeleteTwoTone,
    FolderViewOutlined,
    SmileOutlined,
    AddEmployeeForm
  },
  data() {
    return {
      overflow: { y: window.innerHeight - 300 },
      isModalVisible: false,
      locale: locale,
      project_name: null,
      website: null,
      keywords: null,
      columns: [
        {
          title: "Mã nhâ viên",
          dataIndex: "employee_code",
          key: "employee_code",
        },
        {
          title: "Tên",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Chức vụ",
          dataIndex: "role",
          key: "role",
        },

        {
          title: "ID Group Telegram",
          dataIndex: "telegram_group_id",
          key: "telegram_group_id",
          ellipsis: true,
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          ellipsis: true,
        },
        {
          title: "ID user Telegram",
          dataIndex: "telegram_id",
          key: "telegram_id",
          ellipsis: true,
        },
        {
          title: "Nick Telegram",
          dataIndex: "telegram_name",
          key: "telegram_name",
          ellipsis: true,
        },
        {
          title: "Ngày bắt đầu làm việc",
          dataIndex: "start_work_date",
          key: "start_work_date",
          ellipsis: true,
        },
        {
          title: "Mức lương",
          dataIndex: "salary",
          key: "salary",
          ellipsis: true,
        },
        {
          title: "Note",
          dataIndex: "note",
          key: "note",
          ellipsis: true,
        },
      ],
      data: [],
      searchValue: null,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
        showQuickJumper: true,
      },
    };
  },
  methods: {
    generateUUID() {
      let d = new Date().getTime(); // Timestamp
      let d2 = (performance && performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported

      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16; // Random number between 0 and 16
        if (d > 0) { // Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else { // Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    },
    showSuccess(mesage) {
      notification.success({
        message: 'Success',
        description: mesage,
      });
    },
    showError(mesage) {
      notification.error({
        message: 'Error',
        description: mesage,
      });
    },
   
    showModal() {
      this.isModalVisible = true;
    },
    handleOk() {
      // Logic khi nhấn nút OK (có thể thêm logic để xử lý form ở đây)
      this.isModalVisible = false;
    },
    handleCancel() {
      this.isModalVisible = false;
    },

    formatDate(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    },
    async handleTableChange(pag, filters, sorter) {
      const me = this;
      this.pagination = pag;
      await this.initvalue(me.searchValue);
    },
    //Gọi api lần đầu
    async initvalue(value) {
      const me = this;
      me.searchValue = value
      mask();
      let skip = (me.pagination.current - 1) * me.pagination.pageSize;
      let take = me.pagination.current * me.pagination.pageSize;
      let params = {
        skip: skip,
        take: take,
      };
      if (me.searchValue) {
        params.filter = me.searchValue;
      }
      let ans = (await httpClient.get(`employee`, { params: params }))
        .data;
      me.pagination.total = ans.total;
      me.data = ans.items
      unMask();
    },
  },
  created() {
    // this.dateRange = [moment(), moment()];
    // console.log(this.dateRange)
    this.initvalue();
  },
  updated() {
    const me = this;
    window.addEventListener('resize', function () {
      var windowHeight = window.innerHeight;
      me.overflow.y = windowHeight - 300
    });
  }
};
</script>
<style>
.ant-modal-footer {
  display: none;
}
</style>
<style lang="scss" scoped>
.keyword-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}

.action-list {
  display: flex;
  gap: 12px;
}

.add-website-container {
  width: 500px;
  padding: 0px 10px 0px 0px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;

}

textarea {
  width: calc(100% - 20px);
  height: 200px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.add-proecjt-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-proecjt-btn:hover {
  background-color: #45a049;
}
</style>
<style lang="scss" scoped>
.recognition-page-container {
  // width: 90%;
  // height: 80vh;
  // background-color: #ffff;
  // border-radius: 12px;
  // margin: 24px auto;
  // padding: 24px;

  .page-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .recognition-history {
    padding-top: 24px;

    .table-action {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .real-status {
    background-color: rgb(195, 236, 195);
    color: rgb(33, 156, 33);
    width: fit-content;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
<template>
  <div class="internal-communications-container">
    <div class="recognition-history">
      <div class="table-action">
        <div></div>
        <div class="action-list">
          <a-button @click="openMessageForm">Truyền thông</a-button>
          <a-button @click="showModal">Thêm</a-button>
        </div>
      </div>
      <add-user
        @onAdd="initvalue()"
        :cboTag="cboTag"
        v-model:isShow="modalVisible"
      ></add-user>
      <view-user
        :cboTag="cboTag"
        v-model:isShow="isShowUserDetailFrom"
        v-model:user="currentUser"
      ></view-user>
      <message
        v-model:isShow="isShowMessageModal"
        :selectedRowKeys="selectedRowKeys"
      ></message>

      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :row-selection="rowSelection"
        bordered
        :scroll="overflow"
        size="middle"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'tags'">
            <a-tag v-for="(value, index) in record.tags" :key="index">{{
              value
            }}</a-tag>
          </template>
          <template v-if="column.dataIndex === 'action'">
            <a-popconfirm
              title="Bạn có chắc chắn muốn xóa mục này không?"
              @confirm="handleDelete(record)"
              okText="Yes"
              cancelText="No"
            >
              <a-button type="link" danger>Delete</a-button>
            </a-popconfirm>
            <a-button type="link" danger @click="showDetailForm(record)"
              >View</a-button
            >
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import {
  DeleteTwoTone,
  FolderViewOutlined,
  SmileOutlined,
} from "@ant-design/icons-vue";
import { mask, unMask, showSuccess, showError, showWarning } from "@/utils";
import { httpClient } from "@/apis/httpclient";
import { message, notification } from "ant-design-vue";
import AddUser from "@/views/internal-communications/AddUser.vue";
import ViewUser from "@/views/internal-communications/ViewUser.vue";
import Message from "@/views/internal-communications/Message.vue";
export default {
  components: {
    DeleteTwoTone,
    FolderViewOutlined,
    SmileOutlined,
    AddUser,
    ViewUser,
    Message,
  },
  data() {
    return {
      options: [],
      overflow: { y: window.innerHeight - 250 },
      cboTag: [],
      isShowUserDetailFrom: false,
      currentUser: {},
      isShowMessageModal: false,
      modalVisible: false,
      locale: locale,
      selectedRowKeys: [],
      rowSelection: {
        checkStrictly: true,
        onChange: (selectedRowKeys, selectedRows) => {
          //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          this.selectedRowKeys = selectedRowKeys;
        },
        onSelect: (record, selected, selectedRows) => {},
        onSelectAll: (selected, selectedRows, changeRows) => {},
      },

      data: [],
      cboData: [],
      searchValue: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "I'm",
          dataIndex: "subject",
          key: "subject",
        },
        {
          title: "Tài khoản",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Họ tên đầy đủ",
          dataIndex: "full_name",
          key: "full_name",
        },
        {
          title: "Cách xưng hô",
          dataIndex: "pronounces",
          key: "pronounces",
        },
        {
          title: "Thẻ",
          dataIndex: "tags",
          key: "tags",
          filters: [],
          onFilter: (value, record) => {
            return record.tags.includes(value);
          },
          sorter: (a, b) => a.tags.length - b.tags.length,
          filterSearch: false,
        },
        {
          title: "Thao tác",
          dataIndex: "action",
          key: "action",
        },
      ];
    },
  },
  methods: {
    onSelect(value) {
      this.data = this.cboData.filter((x) => x.tags.includes(value));
    },

    async handleDelete(row) {
      mask();
      const me = this;
      let ans = await httpClient.delete(`alert?username=${row.username}`);
      if (ans) {
        showSuccess("Xoá thành công");
        await this.initvalue();
      } else {
        showError("Xoá thất bại");
      }
      unMask();
    },
    showDetailForm(row) {
      this.isShowUserDetailFrom = true;
      this.currentUser = JSON.parse(JSON.stringify(row));
    },

    generateUUID() {
      let d = new Date().getTime(); // Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported

      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; // Random number between 0 and 16
          if (d > 0) {
            // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },

    showModal() {
      this.modalVisible = true;
    },

    openMessageForm() {
      if (this.selectedRowKeys?.length < 1) {
        showWarning("Vui lòng tích chọn ít nhất một user!");
        return;
      }
      this.isShowMessageModal = true;
    },

    //Gọi api lần đầu
    async initvalue() {
      const me = this;
      mask();
      me.cboTag = (await httpClient.get("alert/tags")).data;
      me.data = (await httpClient.get(`alert`)).data.map((x, index) => {
        let tags = [];
        try {
          if (x.tags) {
            tags = JSON.parse(x.tags);
          }
        } catch (e) {
          console.log(e);
        }
        let ans = { ...x };
        ans["action"] = true;
        ans["tags"] = tags;
        ans["key"] = x.username;
        return ans;
      });
      me.columns[4].filters = [...new Set(me.data.flatMap((x) => x.tags))].map(
        (x) => {
          return { text: x, value: x };
        }
      );
      me.cboData = JSON.parse(JSON.stringify(me.data));
      unMask();
    },
  },
  created() {
    this.initvalue();
  },
  updated() {
    const me = this;
    window.addEventListener("resize", function () {
      var windowHeight = window.innerHeight;
      me.overflow.y = windowHeight - 300;
    });
  },
};
</script>
<style>
.ant-modal-footer {
  display: none;
}
.action-list {
  display: flex;
  gap: 12px;
}
</style>

<style lang="scss" scoped>
.internal-communications-container {
  // width: 90%;
  // height: 80vh;
  // background-color: #ffff;
  // border-radius: 12px;
  // margin: 24px auto;
  // padding: 24px;

  .page-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .recognition-history {
    padding-top: 24px;

    .table-action {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .real-status {
    background-color: rgb(195, 236, 195);
    color: rgb(33, 156, 33);
    width: fit-content;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>

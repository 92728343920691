<template>
  <MainLayout> </MainLayout>
</template>
<script>
import MainLayout from "@/layouts/MainLayout.vue";
export default {
  name: "App",
  components: {
    MainLayout,
  },
  async created() {
    
  },
  mounted(){
   
  }
};
</script>

<style>
#app {
  height: 100vh;
}
</style>

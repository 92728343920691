<template>
  <div class="add_account_form_container">
    <div class="content">
      <a-table :columns="columns" :dataSource="data" :pagination="false" rowKey="key">
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.dataIndex === 'action'">
            <div style="display: flex; gap: 8px">
              <a v-if="data.length > 1" @click="deleteRecord(index)" style="color: red">Xóa</a>
              <a @click="addRecord" v-if="index == data.length - 1">Thêm </a>
            </div>
          </template>
          <template v-else>
            <a-input v-if="index == data.length - 1" v-model:value="record[column.dataIndex]" />
          </template>
        </template>
      </a-table>
    </div>
    <div class="action">
      <a-button type="primary" @click="addAccounts">Thêm</a-button>
    </div>
  </div>
</template>
<script>
import { showWarning, mask, unMask, showError, showSuccess } from "@/utils";
import { httpClient } from "@/apis/httpclient";
export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [{ username: "", password: "" }],
      columns: [
        {
          title: "Tên đăng nhập",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          key: "password",
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
        },
      ],
    };
  },
  methods: {
    deleteRecord(index) {
      this.data.splice(index, 1);
    },
    validate() {
      let isNull = false;
      for (let i = 0; i < this.data.length; i++) {
        if (!this.data[i].username || !this.data[i].password) {
          isNull = true;
          break;
        }
      }
      return isNull;
    },
    addRecord() {
      const isNull = this.validate();
      if (isNull) {
        showWarning("Tên đăng nhập và mật khẩu không được để trống");
        return;
      }
      this.data.push({
        username: "",
        password: "",
      });
    },
    async addAccounts() {
      const isNull = this.validate();
      if (isNull) {
        showWarning("Tên đăng nhập và mật khẩu không được để trống");
        return;
      }

      mask();
      try {
        const website_list = this.selectedRows.map((x) => {
          return x.id;
        });
        const payload = {
          website_list: website_list,
          account_list: this.data,
          action: "ADD_ACCOUNT",
        };
        let ans = (
          await httpClient.post("wp_plugin/maintenance/account", payload)
        ).data;
        console.log(ans);
        if (ans?.status == "error") {
          showError(ans.message);
        } else if (ans?.status == "success") {
          showSuccess("Gửi yêu cầ u đổi thêm tài khoản thành công.");
        }
      } catch {
        showError("Có lỗi xẩy ra");
      }
      unMask();
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss">
.add_account_form_container {
  .c_message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }

  .content {
    margin-bottom: 24px;
  }

  .action {
    display: flex;
    align-self: center;
    justify-content: flex-end;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import routerWebstite from "./router-system";
import Login from "@/views/login/Login";
import GoogleLogin from "@/views/login/GoogleLogin.vue";
import Tracking from "@/views/tracking/Tracking.vue";
import { httpClient } from "@/apis/httpclient";
import { getCookie, deleteAllCookies } from "@/utils";
const routes = [
  {
    path: "/seo-monitor/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/seo-monitor/logout",
    name: "Logout",
  },
  {
    path: "/seo-monitor/google-login",
    name: "GoogleLogin",
    component: GoogleLogin,
    meta: { requiresAuth: true },
  },
  {
    path: "/seo-monitor/wordpress-tracking",
    name: "Tracking",
    component: Tracking,
    meta: { requiresAuth: true },
  },
  ...routerWebstite,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath == "/") {
    router.push({ name: "Login" });
  }
  //xoá token nếu đăng xuất
  if (to.fullPath == "/seo-monitor/logout") {
    localStorage.removeItem("token");
    deleteAllCookies();
    router.push({ name: "Login" });
  }
  //nếu router có yêu cầu authen
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //Kiểm tra xem đã đăng nhập hay chưa nếu chưa thì đưa về trang đăng nhập
    try {
      let token = getCookie("token");
      let ans = await httpClient.post("auth/authen", {
        dependOn: to.meta.dependOn,
        googleToken: token,
      });
      if (ans.data == "NEED_LOGIN_GOOGLE" || ans.data == "NOT_ALLOW") {
        next({
          name: "GoogleLogin",
          query: {
            action: ans.data,
          },
        });
      }
      next();
    } catch (e) {
      console.log(e);
      //Đưa tới trang login
      next({
        name: "Login",
      });
    }
  }
  next();
});

export default router;

<template>
    <a-form :form="form" layout="vertical" @finish="handleFinish" class="employee-form">
        <div class="add-employee-form-container">
            <div style="width: 50%;">
                <a-form-item label="Mã nhân viên" name="employee_code">
                    <a-input v-model:value="formData.employee_code" />
                </a-form-item>

                <a-form-item label="Tên" name="name">
                    <a-input v-model:value="formData.name" />
                </a-form-item>

                <a-form-item label="Chức vụ" name="role">
                    <a-input v-model:value="formData.role" />
                </a-form-item>

                <a-form-item label="ID Group Telegram" name="telegram_group_id">
                    <a-input v-model:value="formData.telegram_group_id" />
                </a-form-item>
                <a-form-item label="Email" name="email">
                    <a-input v-model:value="formData.email" />
                </a-form-item>
            </div>

            <div style="width: 50%;">

                <a-form-item label="ID user Telegram" name="telegram_id">
                    <a-input v-model:value="formData.telegram_id" />
                </a-form-item>

                <a-form-item label="Nick Telegram" name="telegram_name">
                    <a-input v-model:value="formData.telegram_name" />
                </a-form-item>

                <a-form-item label="Ngày bắt đầu làm việc" name="start_work_date">
                    <a-date-picker v-model:value="formData.start_work_date" />
                </a-form-item>

                <a-form-item label="Mức lương" name="salary">
                    <a-input v-model:value="formData.salary" />
                </a-form-item>
                <a-form-item label="Note" name="note">
                    <a-input v-model:value="formData.note" :rows="10" :maxlength="500" showCount />
                </a-form-item>
            </div>

        </div>
        <div class="from-action"> <a-form-item>
                <a-button type="primary" html-type="submit" @click="handleFinish">Thêm</a-button>
            </a-form-item></div>
    </a-form>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import { Form, Input, InputNumber, Button, DatePicker, Input as AInputTextArea } from 'ant-design-vue';
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from 'ant-design-vue';
export default defineComponent({
    components: {
        AForm: Form,
        AInput: Input,
        AInputNumber: InputNumber,
        AButton: Button,
        ADatePicker: DatePicker,
        AInputTextArea,
    },
    setup(props, ctx) {
        // Define emitted events
        const formData = reactive({
            employee_code: '',
            name: '',
            role: '',
            telegram_group_id: '',
            email: '',
            telegram_id: '',
            telegram_name: '',
            start_work_date: null,
            salary: null,
            note: '',
        });

        const handleFinish = async (values) => {
            console.log(formData);
            let ans = await add()
            ctx.emit('close', true);
            ctx.emit('refresh', true);
        };
        const add = async () => {
            mask()
            let ans = await httpClient.post(`employee`, {
                "employee_code": formData.employee_code,
                "name": formData.name,
                "role": formData.role,
                "telegram_group_id": formData.telegram_group_id,
                "email": formData.email,
                "telegram_id": formData.telegram_id,
                "telegram_name": formData.telegram_name,
                "start_work_date": formData.start_work_date,
                "salary": formData.salary,
                "note": formData.note
            })
            if (ans) {
                showSuccess('Thêm thành công')
            } else {
                showError('Thêm thất bại')
            }
            unMask();
        }
        const  showSuccess = (mesage) =>{
            notification.success({
                message: 'Success',
                description: mesage,
            });
        }
        const showError = (mesage) =>{
            notification.error({
                message: 'Error',
                description: mesage,
            });
        }
        return {
            formData,
            handleFinish,
            add
        };
    },
});
</script>

<style scoped>
.add-employee-form-container {
    display: flex;
    gap: 12px
}

.employee-form {
    max-width: 600px;
    margin: 0 auto;
}

.from-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>
<template>
  <div class="recognition-page-container">
    <div class="recognition-history">
      <div class="table-action">
        <div class="table-action-left">
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMultiAction">
                <a-menu-item key="1">
                  <UserOutlined />
                  Gỡ bỏ plugin
                </a-menu-item>
                <a-menu-item key="2">
                  <UserOutlined />
                  Cài plugin
                </a-menu-item>
                <a-menu-item key="3">
                  <UserOutlined />
                  Đổi mật khẩu
                </a-menu-item>
                <a-menu-item key="4">
                  <UserOutlined />
                  Thêm tài khoản
                </a-menu-item>
                <a-menu-item key="5">
                  <UserOutlined />
                  Xóa tài khoản ngòai white list
                </a-menu-item>
              </a-menu>
            </template>
            <a-button>
              Hành động hàng loạt
              <DownOutlined />
            </a-button>
          </a-dropdown>
          <a-input-search placeholder="Tìm kiếm theo url website hoặc tên đăng nhập" style="width: 400px"
            :enter-button="true" @search="initvalue" v-model:value="searchValue" />
          <div class="brand-filter">
            <label>Brand</label>
            <a-dropdown>
              <template #overlay>
                <a-menu @click="handleFilter">
                  <a-menu-item v-for="(item, index) in cboBrand" :key="index">
                    <UserOutlined />
                    {{ item.text }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                {{ brand.text }}
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </div>
        </div>

        <div class="action-list">
          <a-button @click="showModal">Thêm</a-button>
          <a-button @click="() => (isShowNotifySetup = true)">Thiết lập</a-button>
        </div>
      </div>
      <a-modal :width="700" title="Thêm Website Mới" v-model:visible="isModalVisible" @ok="handleOk"
        @cancel="handleCancel">
        <AddWordpressForm @close="handleCancel" @refresh="initvalue" />
      </a-modal>
      <a-modal :width="700" title="Xóa plugin hàng loạt" v-model:visible="isShowMultiRemoveForm" @ok="handleOk"
        @cancel="handleCancel">
        <MultiRemoveForm @close="handleCancel" @multiRemove="multiRemove" />
      </a-modal>
      <a-modal :width="700" title="Đổi mật khẩu" v-model:visible="isShowChangePasswordForm" @ok="handleOk"
        @cancel="handleCancel">
        <ChangePasswordForm :selectedRows="selectedRows" @close="handleCancel" />
      </a-modal>
      <a-modal :width="700" title="Thiết lập" v-model:visible="isShowNotifySetup" @ok="handleOk" @cancel="handleCancel">
        <NotifySetup @close="handleCancel" />
      </a-modal>
      <a-modal :width="700" title="Cài đặt plugin" v-model:visible="isShowMultiInstallForm" @ok="handleOk"
        @cancel="handleCancel">
        <MultiInstallPluginForm :selectedRows="selectedRows" @close="handleCancel" @multiRemove="multiRemove" />
      </a-modal>
      <a-modal :width="700" title="Thêm tài khoản" v-model:visible="isShowAddUserForm" @ok="handleOk"
        @cancel="handleCancel">
        <AddAccountFrom :selectedRows="selectedRows" @close="handleCancel" />
      </a-modal>
      <a-modal :width="700" title="Xóa account ngoài white list" v-model:visible="isShowDeleteAccountForm"
        @ok="handleOk" @cancel="handleCancel">
        <DeleteAccount :selectedRows="selectedRows" @close="handleCancel" />
      </a-modal>
      <a-table :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection"
        @change="handleTableChange" bordered size="middle" :scroll="overflow">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'action'">
            <a style="color: red" @click="handleDelete(record.id)"> Xóa</a>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import {
  DeleteTwoTone,
  FolderViewOutlined,
  SmileOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
import { mask, unMask } from "@/utils";
import { httpClient } from "@/apis/httpclient";
import { notification } from "ant-design-vue";
import AddWordpressForm from "./AddWordpressForm.vue";
import MultiRemoveForm from "./MultiRemoveForm.vue";
import MultiInstallPluginForm from "./MultiInstallPluginForm.vue";
import NotifySetup from "./NotifySetup.vue";
import ChangePasswordForm from "./ChangePasswordForm.vue";
import AddAccountFrom from "./AddAccountFrom.vue";
import DeleteAccount from "./DeleteAccount.vue";
export default {
  components: {
    DeleteTwoTone,
    FolderViewOutlined,
    SmileOutlined,
    DownOutlined,
    AddWordpressForm,
    MultiRemoveForm,
    UserOutlined,
    MultiInstallPluginForm,
    NotifySetup,
    ChangePasswordForm,
    AddAccountFrom,
    DeleteAccount,
  },
  data() {
    const me = this;
    return {
      isShowChangePasswordForm: false,
      overflow: { y: window.innerHeight - 125 },
      isModalVisible: false,
      locale: locale,
      project_name: null,
      website: null,
      keywords: null,
      brand: { value: "", text: "Tất cả" },
      isShowMultiInstallForm: false,
      isShowMultiRemoveForm: false,
      isShowNotifySetup: false,
      isShowAddUserForm: false,
      isShowDeleteAccountForm: false,
      cboBrand: [],
      columns: [
        {
          title: "Trang quản trị",
          dataIndex: "admin_url",
          key: "admin_url",
        },
        {
          title: "Tên đăng nhập",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          key: "password",
        },

        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
          ellipsis: true,
        },
        {
          titile: "Hành động",
          dataIndex: "action",
          key: "action",
        },
      ],
      data: [],
      searchValue: null,
      selectedRowKeys: [],
      selectedRows: [],
      rowSelection: {
        checkStrictly: true,
        onChange: (selectedRowKeys, selectedRows) => {
          //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          this.selectedRows = selectedRows;
          this.selectedRowKeys = selectedRowKeys;
        },
        onSelect: (record, selected, selectedRows) => { },
        onSelectAll: (selected, selectedRows, changeRows) => { },
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
        showQuickJumper: true,
      },
    };
  },
  methods: {
    showSuccess(mesage) {
      notification.success({
        message: "Success",
        description: mesage,
      });
    },
    showError(mesage) {
      notification.error({
        message: "Error",
        description: mesage,
      });
    },
    showWarning(mesage) {
      notification.warn({
        message: "Warning",
        description: mesage,
      });
    },

    showModal() {
      this.isModalVisible = true;
    },
    handleOk() {
      // Logic khi nhấn nút OK (có thể thêm logic để xử lý form ở đây)
      this.isModalVisible = false;
      this.isShowMultiRemoveForm = false;
      this.isShowMultiInstallForm = false;
    },
    handleCancel() {
      this.isModalVisible = false;
      this.isShowMultiRemoveForm = false;
      this.isShowMultiInstallForm = false;
      this.isShowNotifySetup = false;
      this.isShowChangePasswordForm = false;
      this.isShowAddUserForm = false;
      this.isShowDeleteAccountForm = false;
    },

    async handleTableChange(pag, filters, sorter) {
      const me = this;
      this.pagination = pag;
      await this.initvalue(me.searchValue);
    },
    //Gọi api lần đầu
    async initvalue(value) {
      const me = this;
      me.searchValue = value;
      mask();

      let skip = (me.pagination.current - 1) * me.pagination.pageSize;
      let take = me.pagination.current * me.pagination.pageSize;
      let params = {
        skip: skip,
        take: take,
        brand: me.brand?.value ?? null,
        filter: "",
      };
      if (me.searchValue) {
        params.filter = me.searchValue;
      }
      let ans = (await httpClient.get(`wp_plugin/websites`, { params: params }))
        .data;
      me.pagination.total = ans.total;
      me.data = ans.items.map((x) => {
        return { ...x, key: x.id };
      });
      const cboBrand = (
        await httpClient.get(`wp_plugin/websites/brand`)
      ).data.map((x) => {
        return {
          value: x,
          text: x,
        };
      });
      cboBrand.splice(0, 0, { value: "", text: "Tất cả" });
      me.cboBrand = cboBrand;
      let telgram_id = (await httpClient.post("/wp_plugin/notify-config")).data;
      if (!telgram_id) {
        me.isShowNotifySetup = true;
      }
      unMask();
    },
    async handleMultiAction(e) {
      if (this.selectedRowKeys.length == 0) {
        this.showWarning("Vui lòng chọn ít nhất 1 website");
        return;
      }
      mask();
      let telgram_id = (await httpClient.post("/wp_plugin/notify-config")).data;
      if (!telgram_id) {
        this.isShowNotifySetup = true;
        unMask();
        return;
      }
      unMask();
      if (e.key == 1) {
        console.log(this.selectedRows);
        this.isShowMultiRemoveForm = true;
      } else if (e.key == 2) {
        this.isShowMultiInstallForm = true;
      } else if (e.key == 3) {
        this.isShowChangePasswordForm = true;
      } else if (e.key == 4) {
        this.isShowAddUserForm = true;
      } else if (e.key == 5) {
        this.isShowDeleteAccountForm = true;
      }
    },
    async multiRemove(value, action = "REMOVE") {
      mask();
      const plugins = value
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x.length > 0);
      const website_list = this.selectedRows.map((x) => {
        return x.id;
      });
      let ans = await httpClient.post(
        `wp_plugin/maintenance/plugins?action=${action}`,
        {
          plugins: plugins,
          website_list: website_list,
        }
      ).data;
      if (ans?.status == "error") {
        this.showError(ans.message);
      } else if (ans?.status == "success") {
        this.showError("Yêu cầu đã được tiếp nhận");
      }
      this.isShowMultiRemoveForm = false;
      unMask();
    },
    async handleDelete(id) {
      mask();
      try {
        await httpClient.delete(`wp_plugin/websites?id=${id}`);
        this.showSuccess();
      } catch (error) {
        this.showError();
      }
      this.initvalue();
      unMask();
    },
    handleFilter(e) {
      const me = this;
      me.brand = me.cboBrand[e.key];
      me.initvalue();
    },
  },

  async created() {
    this.initvalue();
  },
  updated() {
    const me = this;
    window.addEventListener("resize", function () {
      var windowHeight = window.innerHeight;
      me.overflow.y = windowHeight - 300;
    });
  },
};
</script>
<style lang="scss">
.ant-modal-footer {
  display: none;
}
</style>
<style lang="scss" scoped>
.keyword-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-block {
  .filter-block-content {
    display: flex;
    gap: 12px;

    .filter-name {
      width: 150px;
    }
  }
}

.action-list {
  display: flex;
  gap: 12px;
}

.add-website-container {
  width: 500px;
  padding: 0px 10px 0px 0px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

textarea {
  width: calc(100% - 20px);
  height: 200px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.add-proecjt-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-proecjt-btn:hover {
  background-color: #45a049;
}
</style>
<style lang="scss" scoped>
.recognition-page-container {
  // width: 90%;
  // height: 80vh;
  // background-color: #ffff;
  // border-radius: 12px;
  // margin: 24px auto;
  // padding: 24px;

  .page-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .recognition-history {
    padding-top: 24px;

    .table-action {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
      align-items: flex-end;

      .table-action-left {
        display: flex;
        gap: 24px;
        align-items: center;

        .brand-filter {
          display: flex;
          align-items: center;
          gap: 8px;

          label {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .real-status {
    background-color: rgb(195, 236, 195);
    color: rgb(33, 156, 33);
    width: fit-content;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>

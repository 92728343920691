<template>
    <a-modal v-model:visible="isShowMessageModal" title="Truyền thông đến tổ chức của bạn">
        <!-- Custom content slot -->
        <template #default>
            <div class="message-container">
                <a-tag color="#2db7f5" @click="inserTag('{_@@subject@@_}')" style="cursor: pointer;">
                    I'm
                </a-tag>
                <a-tag @click="inserTag('{_@@pronouns@@_}')" style="cursor: pointer;" :color="'green'">Cách xưng
                    hô </a-tag>
                <a-tag @click="inserTag('{_@@full_name@@_}')" style="cursor: pointer;" color="#f50">
                    Họ tên đầy đủ
                </a-tag>
                <a-tag @click="inserTag('{_@@ref_webs@@_}')" style="cursor: pointer;" color="#108ee9">
                    Webs
                </a-tag>
                <a-tag @click="inserTag('{_@@ref_tags@@_}')" style="cursor: pointer;" color="#108ee9">
                    Tags
                </a-tag>
                <label style="margin-top: 12px;" for="message">Message <span style="color: red;">*</span></label>
                <textarea id="message" name="message" v-model="message" maxlength="3900"></textarea>
                <input type="file" id="fileInput" accept="image/*">
                <div style="display: flex; align-items: center; justify-content: flex-end;padding-right: 20px;">
                    <button class="add-proecjt-btn" @click="sendMessage" type="submit">Gửi</button>
                </div>
            </div>
        </template>

        <!-- No footer slot -->
        <template #footer>
            <!-- You can leave this empty to remove the footer -->
        </template>
    </a-modal>
</template>

<script>
import { mask, unMask, showSuccess, showError, showWarning } from "@/utils";
import { httpClient } from "@/apis/httpclient";
export default {
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        selectedRowKeys: {
            type: Array,
            default: () => { }
        }
    },
    data() {
        return {
            message: "",
        }
    },
    computed: {
        isShowMessageModal: {
            get() {
                return this.isShow
            },
            set(value) {
                this.$emit('update:isShow', value);
            }
        }
    },
    methods: {
        async sendMessage() {
            if (!this.message) {
                showWarning('Message không đuợc để trống')
                return;
            }
            mask()
            const fileInput = document.getElementById('fileInput');
            const file = fileInput.files[0];

            // Tạo FormData và thêm file vào
            const formData = new FormData();
            let username_list = this.selectedRowKeys.join(' ')
            if (file) {
                formData.append('file', file);
            }
            formData.append('username_list', username_list)
            formData.append('message', this.message)
            let ans = (await httpClient.postForm('alert/message', formData)).data
            showSuccess(`Gửi thành công ${ans}/${this.selectedRowKeys.length}`)
            document.getElementById('fileInput').value = null
            this.message = ''
            unMask()
        },
        inserTag(tag) {
            this.message = `${this.message}${tag}`
        },
    }
}
</script>
<style lang="scss" scoped>
.message-container {
    width: 500px;
    padding: 0px 10px 0px 0px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;

}

textarea {
    width: calc(100% - 20px);
    height: 200px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
}

.add-proecjt-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.add-proecjt-btn:hover {
    background-color: #45a049;
}
</style>
<template>
  <div class="recognition-page-container">
    <div class="recognition-history">
      <div class="table-action">
        <a-input-search
          placeholder="Tìm kiếm theo url website hoặc theo từ khóa"
          style="width: 400px"
          :enter-button="true"
          @search="initvalue"
        />

        <div class="action-list">
          <a-button @click="getWebsites">Tải xuống</a-button>
          <a-button @click="showModal">Thêm</a-button>
          <a-upload
            multiple
            :before-upload="beforeUpload"
            :custom-request="handleUpload"
            accept=".xlsx"
          >
            <a-button> Ahrefs </a-button>
          </a-upload>
          <a-dropdown-button>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1">
                  <div @click="dataSynthesis">
                    <FolderViewOutlined /> Tổng hợp dữ liệu
                  </div>
                </a-menu-item>
                <a-menu-item key="2">
                  <div @click="dailyReport">
                    <FolderViewOutlined /> Báo cáo ngày
                  </div>
                </a-menu-item>
                <a-menu-item key="3">
                  <div @click="weeklyReport">
                    <FolderViewOutlined /> Báo cáo tuần
                  </div>
                </a-menu-item>
                <a-menu-item key="4">
                  <div @click="monthlyReport">
                    <FolderViewOutlined /> Báo cáo tháng
                  </div>
                </a-menu-item>
                <a-menu-item key="5">
                  <div @click="pendingAlert">
                    <FolderViewOutlined />
                    {{
                      !pendding ? "Tạm dừng nhắc cập nhật" : "Bật nhắc cập nhật"
                    }}
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </div>
      </div>
      <a-modal
        v-model:visible="modalVisible"
        title="Nhập thông tin chi tiết cho dự án mới của bạn"
      >
        <!-- Custom content slot -->
        <template #default>
          <div class="add-website-container">
            <label for="project-name">Project Name*</label>
            <input
              type="text"
              id="project-name"
              name="project-name"
              placeholder="Project Name"
              v-model="project_name"
            />

            <label for="domain">Domain*</label>
            <input
              type="text"
              id="domain"
              name="domain"
              placeholder="your-domain.com"
              v-model="website"
            />
            <label for="employee_id">Employee ID*</label>
            <input
              type="text"
              id="domain"
              name="domain"
              placeholder="NV01"
              v-model="employee_id"
            />

            <label for="keywords">Keywords*</label>
            <textarea
              id="keywords"
              name="keywords"
              v-model="keywords"
              placeholder="Bulk add keywords by pasting in a list (one keyword per line)"
            ></textarea>

            <button class="add-proecjt-btn" @click="addProject" type="submit">
              Add Project
            </button>
          </div>
        </template>

        <!-- No footer slot -->
        <template #footer>
          <!-- You can leave this empty to remove the footer -->
        </template>
      </a-modal>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleTableChange"
        bordered
        size="middle"
        :scroll="overflow"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'keywords'">
            <a-collapse
              v-model:activeKey="record.activeKey"
              :bordered="false"
              style="background: transparent"
            >
              <a-collapse-panel
                :key="1"
                :header="` ${record.keywords.length} từ khóa`"
              >
                <div class="keyword-list">
                  <a-tag
                    :color="'green'"
                    v-for="(keyword, index) in record.keywords"
                    :key="index"
                  >
                    {{ keyword }}
                  </a-tag>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import {
  DeleteTwoTone,
  FolderViewOutlined,
  SmileOutlined,
} from "@ant-design/icons-vue";
import { mask, unMask } from "@/utils";
import moment from "moment";
import { httpClient } from "@/apis/httpclient";
import { notification } from "ant-design-vue";
import { message } from "ant-design-vue";
export default {
  components: {
    DeleteTwoTone,
    FolderViewOutlined,
    SmileOutlined,
  },
  data() {
    return {
      overflow: { y: window.innerHeight - 300 },
      modalVisible: false,
      locale: locale,
      project_name: null,
      website: null,
      keywords: null,
      employee_id: null,
      pendding: false,
      columns: [
        {
          title: "Tài khoản",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          key: "password",
        },
        {
          title: "Tên dự án",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Liên kết",
          dataIndex: "url",
          key: "url",
        },
        {
          title: "Từ khóa",
          dataIndex: "keywords",
          key: "keywords",
          ellipsis: true,
        },
      ],
      data: [],
      searchValue: null,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
        showQuickJumper: true,
      },
    };
  },
  methods: {
    beforeUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("You can only upload .xlsx files!");
      }
      return isXlsx;
    },
    async handleUpload({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await httpClient.post("log/ahrefs", formData, {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data);
        onSuccess();
      } catch (error) {
        console.error(error);
        message.error("Upload failed.");
        onError();
      }
    },

    generateUUID() {
      let d = new Date().getTime(); // Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported

      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; // Random number between 0 and 16
          if (d > 0) {
            // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    showSuccess(mesage) {
      notification.success({
        message: "Success",
        description: mesage,
      });
    },
    showError(mesage) {
      notification.error({
        message: "Error",
        description: mesage,
      });
    },
    async pendingAlert() {
      mask();
      const me = this;
      try {
        await httpClient.get(`statistic/public/alert?pendding=${!me.pendding}`);
        me.initvalue();
      } catch (e) {
        console.log(e);
      }

      unMask();
    },
    async dailyReport() {
      mask();
      const me = this;
      try {
        await httpClient.get(`statistic/public/report/daily`);
        me.showSuccess("Tổng hợp dữ liệu thành công");
      } catch (e) {
        me.showError("Tổng hợp dữ liệu thất bại");
        console.log(e);
      }

      unMask();
    },
    async weeklyReport() {
      mask();
      const me = this;
      try {
        await httpClient.get(`statistic/public/report/weekly_report`);
        me.showSuccess("Gửi báo cáo ngày tới nhân viên thành công");
      } catch (e) {
        me.showError("Gửi báo cáo ngày tới nhân viên thất bại");
        console.log(e);
      }

      unMask();
    },
    async monthlyReport() {
      mask();
      const me = this;
      try {
        await httpClient.get(`statistic/public/report/monthly_report`);
        me.showSuccess("Gửi báo cáo tháng tới nhân viên thành công");
      } catch (e) {
        me.showError("Gửi báo cáo tháng tới nhân viên thất bại");
        console.log(e);
      }

      unMask();
    },
    async dataSynthesis() {
      mask();
      const me = this;
      try {
        await httpClient.get(`statistic/public`);
        await httpClient.get(`/statistic/public/report/chart_data`);
        me.showSuccess("Tổng hợp dữ liệu thành công");
      } catch (e) {
        me.showError("Tổng hợp dữ liệu thất bại");
        console.log(e);
      }

      unMask();
    },
    async addProject() {
      mask();
      const me = this;
      let ans = await httpClient.post(`serprobot/website`, {
        url: me.website,
        project_name: me.project_name,
        keywords: me.keywords
          .split("\n")
          .filter((x) => !!x)
          .slice(0, 10),
        employee_id: me.employee_id,
      });
      if (ans) {
        me.showSuccess("Thêm thành công");
      } else {
        me.showError("Thêm thất bại");
      }
      this.project_name = null;
      this.website = null;
      this.keywords = null;
      this.modalVisible = false;
      await this.initvalue(me.searchValue);
      unMask();
    },
    showModal() {
      this.modalVisible = true;
    },
    async getWebsites() {
      mask();

      // Create a link element
      const link = document.createElement("a");

      // Set the link's href to the object URL
      link.href = `${window.__config.host_base}serprobot/website/name`;

      // Append the link to the document
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      unMask();
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    },
    async handleTableChange(pag, filters, sorter) {
      const me = this;
      this.pagination = pag;
      await this.initvalue(me.searchValue);
    },
    //Gọi api lần đầu
    async initvalue(value) {
      const me = this;
      me.pendding = (
        await httpClient.get("statistic/public/alert/status")
      ).data;
      me.searchValue = value;
      mask();
      try {
        let skip = (me.pagination.current - 1) * me.pagination.pageSize;
        let take = me.pagination.current * me.pagination.pageSize;
        let params = {
          skip: skip,
          take: take,
        };
        if (me.searchValue) {
          params.filter = me.searchValue;
        }
        let ans = (
          await httpClient.get(`serprobot/website`, { params: params })
        ).data;
        me.pagination.total = ans.total;
        me.data = ans.items.map((x) => {
          return { ...x, keywords: JSON.parse(x.keywords), activeKey: 0 };
        });
      } catch (e) {
        console.log(e);
      }
      unMask();
    },
  },
  created() {
    // this.dateRange = [moment(), moment()];
    // console.log(this.dateRange)
    this.initvalue();
  },
  updated() {
    const me = this;
    window.addEventListener("resize", function () {
      var windowHeight = window.innerHeight;
      me.overflow.y = windowHeight - 300;
    });
  },
};
</script>
<style>
.ant-modal-footer {
  display: none;
}
</style>
<style lang="scss" scoped>
.keyword-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}

.action-list {
  display: flex;
  gap: 12px;
}

.add-website-container {
  width: 500px;
  padding: 0px 10px 0px 0px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

textarea {
  width: calc(100% - 20px);
  height: 200px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.add-proecjt-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-proecjt-btn:hover {
  background-color: #45a049;
}
</style>
<style lang="scss" scoped>
.recognition-page-container {
  // width: 90%;
  // height: 80vh;
  // background-color: #ffff;
  // border-radius: 12px;
  // margin: 24px auto;
  // padding: 24px;

  .page-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .recognition-history {
    padding-top: 24px;

    .table-action {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .real-status {
    background-color: rgb(195, 236, 195);
    color: rgb(33, 156, 33);
    width: fit-content;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>

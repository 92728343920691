<template>
  <div class="recognition-page-container">
    <div class="recognition-history">
      <div class="table-action">
        <a-input-search
          placeholder="Tìm kiếm theo url website, thiết bị, hoặc địa chị mạng"
          style="width: 400px"
          :enter-button="true"
          @search="initvalue"
        />
        <div style="display: flex; gap: 12px">
          <a-date-picker v-model:value="date" />
          <a-button @click="openSettingPopup">Thiết lập</a-button>
        </div>
      </div>
      <a-modal
        :width="700"
        title="Thiết lập"
        v-model:visible="isShowConfigPopup"
      >
        <div class="seting-block">
          <div class="text-content">{{ apiKey }}</div>
          <a-button @click="refreshApiKey">Tạo mới api key</a-button>
        </div>
        <div class="seting-block">
          <a-input
            v-model:value="telegram_id"
            placeholder="Id telegram nhận thông báo"
          />
          <a-button @click="saveApiKey">Lưu</a-button>
        </div>
      </a-modal>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleTableChange"
        bordered
        size="middle"
        :scroll="overflow"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'keywords'">
            <a-collapse
              v-model:activeKey="record.activeKey"
              :bordered="false"
              style="background: transparent"
            >
              <a-collapse-panel
                :key="1"
                :header="` ${record.keywords.length} từ khóa`"
              >
                <div class="keyword-list">
                  <a-tag
                    :color="'green'"
                    v-for="(keyword, index) in record.keywords"
                    :key="index"
                  >
                    {{ keyword }}
                  </a-tag>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/vi_VN";
import {
  DeleteTwoTone,
  FolderViewOutlined,
  SmileOutlined,
} from "@ant-design/icons-vue";
import { mask, unMask } from "@/utils";
import { httpClient } from "@/apis/httpclient";
import { notification } from "ant-design-vue";
import dayjs from "dayjs";
export default {
  components: {
    DeleteTwoTone,
    FolderViewOutlined,
    SmileOutlined,
  },
  data() {
    return {
      overflow: { y: window.innerHeight - 300 },
      locale: locale,
      isShowConfigPopup: false,
      apiKey: null,
      telegram_id: null,
      columns: [
        {
          title: "Trang chủ",
          dataIndex: "home_url",
          key: "home_url",
        },
        {
          title: "Tên đăng nhập",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Id thiết bị",
          dataIndex: "device_id",
          key: "device_id",
        },
        {
          title: "Đia chỉ mạng",
          dataIndex: "ip_address",
          key: "ip_address",
        },
        {
          title: "Thời điểm truy cập",
          dataIndex: "created_date",
          key: "created_date",
          ellipsis: true,
        },
        {
          title: "Ghi chú",
          dataIndex: "note",
          key: "note",
          ellipsis: true,
        },
      ],
      data: [],
      searchValue: null,
      date: dayjs(),
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
        showQuickJumper: true,
      },
    };
  },
  methods: {
    showSuccess(mesage) {
      notification.success({
        message: "Success",
        description: mesage,
      });
    },
    showError(mesage) {
      notification.error({
        message: "Error",
        description: mesage,
      });
    },
    async saveApiKey() {
      mask();
      try {
        await httpClient.post(`tracking/telegram_id?value=${this.telegram_id}`);
        this.showSuccess("Lưu thành công");
      } catch {
        this.showError("Lưu thất bại");
      }
      unMask();
    },
    async handleTableChange(pag, filters, sorter) {
      const me = this;
      this.pagination = pag;
      await this.initvalue(me.searchValue);
    },
    async refreshApiKey() {
      mask();
      const me = this;
      await httpClient.post("tracking/api-key");
      try {
        me.apiKey = (await httpClient.get(`tracking/api-key`)).data;
        this.showSuccess("Tạo thành công");
      } catch {
        this.showError("Tạo thất bại");
      }
      unMask();
    },
    async openSettingPopup() {
      const me = this;
      me.apiKey = (await httpClient.get(`tracking/api-key`)).data;
      me.telegram_id = (await httpClient.get(`tracking/telegram_id`)).data;
      me.isShowConfigPopup = true;
    },
    //Gọi api lần đầu
    async initvalue(value) {
      const me = this;
      me.searchValue = value;
      mask();
      let skip = (me.pagination.current - 1) * me.pagination.pageSize;
      let take = me.pagination.current * me.pagination.pageSize;
      let params = {
        skip: skip,
        take: take,
        filter: me.searchValue ?? "",
        date: me.date.format("DD-MM-YYYY"),
      };
      if (me.searchValue) {
        params.filter = me.searchValue;
      }
      let ans = (await httpClient.get(`tracking/list`, { params: params }))
        .data;
      me.pagination.total = ans.total;
      me.data = ans.items;
      unMask();
    },
  },
  created() {
    // this.dateRange = [moment(), moment()];
    // console.log(this.dateRange)
    this.initvalue();
    const me = this;
  },
  watch: {
    date() {
      this.initvalue();
    },
  },
  updated() {
    const me = this;
    window.addEventListener("resize", function () {
      var windowHeight = window.innerHeight;
      me.overflow.y = windowHeight - 300;
    });
  },
};
</script>
<style>
.ant-modal-footer {
  display: none;
}
</style>
<style lang="scss" scoped>
.keyword-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
}

.add-website-container {
  width: 500px;
  padding: 0px 10px 0px 0px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

textarea {
  width: calc(100% - 20px);
  height: 200px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.add-proecjt-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-proecjt-btn:hover {
  background-color: #45a049;
}
</style>
<style lang="scss" scoped>
.recognition-page-container {
  // width: 90%;
  // height: 80vh;
  // background-color: #ffff;
  // border-radius: 12px;
  // margin: 24px auto;
  // padding: 24px;

  .page-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .recognition-history {
    padding-top: 24px;

    .table-action {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .real-status {
    background-color: rgb(195, 236, 195);
    color: rgb(33, 156, 33);
    width: fit-content;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
}
.seting-block {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0px;
  .text-content {
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  input {
    margin: 0px;
    height: 32px;
  }
}
</style>

<template>
  <div class="chart-container">
    <div class="filters">
      <a-select
        v-model:value="selectedEmployee"
        @change="updateChart"
        placeholder="Chọn nhân viên"
        style="width: 200px"
      >
        <a-select-option
          v-for="employee in employees"
          :key="employee.telegram_id"
          :value="employee.telegram_id"
        >
          {{ employee.name }} - {{ employee.employee_code }}
        </a-select-option>
      </a-select>

      <a-select
        v-model:value="selectedMonth"
        @change="updateChart"
        placeholder="Chọn tháng"
        style="width: 200px"
      >
        <a-select-option v-for="month in months" :key="month" :value="month">
          {{ month }}
        </a-select-option>
      </a-select>
    </div>
    <div class="line-chart">
      <Line v-if="loaded" :data="data" :options="options" />
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/apis/httpclient";
import { mask, unMask, parseJwt } from "@/utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: {
    Line,
  },
  created() {},
  data() {
    const currentMonth = new Date().getMonth() + 1; // Lấy tháng hiện tại (0-11) và cộng thêm 1
    const months = Array.from({ length: 12 }, (_, i) => `Tháng ${i + 1}`);
    const selectedMonth = `Tháng ${currentMonth}`;

    return {
      loaded: false,
      employees: [],
      selectedEmployee: null,
      months: months,
      selectedMonth: selectedMonth,
      data: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    async init() {
      mask();
      try {
        let employee = (await httpClient.get("/employee?skip=-1&take=-1")).data;
        this.employees = employee.items;
        if (this.employees?.length > 0) {
          this.selectedEmployee = this.employees[0].telegram_id;
        }
      } catch (e) {
        console.log(e);
      }

      unMask();
    },

    async updateChart() {
      mask();
      this.loaded = false;
      let query =
        "T" +
        this.selectedMonth.split(" ")[1].padStart(2, "0") +
        "-" +
        new Date().getFullYear();
      let statistic_data = (
        await httpClient.get(
          `/statistic/public/report/chart_data?time=${query}&telegram_id=${this.selectedEmployee}`
        )
      ).data;
      this.statistic_data2chart_data(statistic_data);
      this.loaded = true;
      unMask();
    },

    statistic_data2chart_data(statistic_data) {
      let ans = {
        labels: statistic_data?.day_list ?? [],
        datasets: [
          {
            label: "Tổng website",
            backgroundColor: "rgba(255, 99, 132, 0.2)", // Bộ màu 1
            borderColor: "rgba(255, 99, 132, 1)", // Bộ màu 1
            pointBackgroundColor: "rgba(255, 99, 132, 1)", // Bộ màu 1
            data: statistic_data?.total_website ?? [],
          },
          {
            label: "Tổng keyword",
            backgroundColor: "rgba(54, 162, 235, 0.2)", // Bộ màu 2
            borderColor: "rgba(54, 162, 235, 1)", // Bộ màu 2
            pointBackgroundColor: "rgba(54, 162, 235, 1)", // Bộ màu 2
            data: statistic_data?.total_keyword ?? [],
          },
          {
            label: "Tổng website vào top",
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Bộ màu 3
            borderColor: "rgba(75, 192, 192, 1)", // Bộ màu 3
            pointBackgroundColor: "rgba(75, 192, 192, 1)", // Bộ màu 3
            data: statistic_data?.total_website_ontop ?? [],
          },
          {
            label: "Tổng ngày vào top",
            backgroundColor: "rgba(153, 102, 255, 0.2)", // Bộ màu 4
            borderColor: "rgba(153, 102, 255, 1)", // Bộ màu 4
            pointBackgroundColor: "rgba(153, 102, 255, 1)", // Bộ màu 4
            data: statistic_data?.total_day_ontop ?? [],
          },
        ],
      };
      this.data = ans;
    },
  },
  async mounted() {
    await this.init();
    this.updateChart(); // Cập nhật biểu đồ khi tải trang
  },
};
</script>

<style scoped lang="scss">
.chart-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  height: 60vh;
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  gap: 16px;

  .filters {
    display: flex;
    justify-content: space-between;
  }

  .line-chart {
    flex: 1;
  }
}
</style>

<template>
  <a-form layout="vertical" @finish="handleFinish" class="wordpress-form">
    <div class="notify-setup-form-container">
      <a-form-item label="Telegram nhận thông báo" name="telegram_id">
        <a-input v-model:value="telegram_id" />
      </a-form-item>
    </div>

    <div class="from-action">
      <a-button type="primary" html-type="submit" @click="checkConection"
        >Kiểm tra</a-button
      >
      <a-button html-type="submit" @click="saveConnection">Lưu</a-button>
    </div>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Input as AInputTextArea,
  message,
} from "ant-design-vue";
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from "ant-design-vue";
export default defineComponent({
  components: {
    AForm: Form,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ADatePicker: DatePicker,
    AInputTextArea,
  },
  setup(props, ctx) {
    const handleFinish = async (values) => {
      ctx.emit("close", true);
    };
    const showSuccess = (mesage) => {
      notification.success({
        message: "Success",
        description: mesage,
      });
    };
    const showError = (mesage) => {
      notification.error({
        message: "Error",
        description: mesage,
      });
    };
    const showWarning = (mesage) => {
      notification.warn({
        message: "Warning",
        description: mesage,
      });
    };
    const checkConection = async () => {
      if (!telegram_id.value) {
        showWarning("Telegram id không được để trống");
        return;
      }
      mask();
      let res = (
        await httpClient.get(
          `wp_plugin/connection?chat_id=${telegram_id.value}`
        )
      ).data;
      if (res) {
        showSuccess("Kết nối thành công");
      } else {
        showError("Kết nối thất bại");
      }
      unMask();
    };
    const saveConnection = async () => {
      if (!telegram_id.value) {
        showWarning("Telegram id không được để trống");
        return;
      }
      mask();
      try {
        let res = (
          await httpClient.post(
            `wp_plugin/connection?chat_id=${telegram_id.value}`
          )
        ).data;
        if (res == true) {
          showSuccess("Lưu thông tin thiết lập thành công");
          ctx.emit("close", true);
        } else {
          showError("Lưu thông tin thiết lập thất bạn");
        }
      } catch {
        showError("Có lỗi xẩy");
      }
      unMask();
    };
    const telegram_id = ref();
    const init = async () => {
      telegram_id.value = (
        await httpClient.post("/wp_plugin/notify-config")
      ).data;
    };
    init();
    return {
      handleFinish,
      telegram_id,
      checkConection,
      saveConnection,
    };
  },
});
</script>

<style scoped lang="scss">
.multi-remove-form-container {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  flex-direction: column;
}

.from-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>

<template>
  <a-form layout="vertical" @finish="handleFinish" class="wordpress-form">
    <div class="delete-account-form-container">
      <a-textarea v-model:value="textValue" :rows="8"
        placeholder="Nhập account muốn giũ lại(các account ngoài danh sách sẽ bị xóa), mỗi account nằm trên một dòng" />
    </div>

    <div class="from-action">
      <a-button type="primary" html-type="submit" @click="deleteAccounts">Xóa</a-button>
    </div>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Input as AInputTextArea,
} from "ant-design-vue";
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from "ant-design-vue";
export default defineComponent({
  components: {
    AForm: Form,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ADatePicker: DatePicker,
    AInputTextArea,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const textValue = ref();
    const showSuccess = (mesage) => {
      notification.success({
        message: "Success",
        description: mesage,
      });
    };
    const showError = (mesage) => {
      notification.error({
        message: "Error",
        description: mesage,
      });
    };
    const deleteAccounts = async () => {
      if (!textValue.value) {
        showWarning("Mời nhập tên plugin cần xóa");
      } else {
        mask();
        try {
          const website_list = props.selectedRows.map((x) => {
            return x.id;
          });
          const payload = {
            website_list: website_list,
            white_list: textValue.value.split("\n"),
            action: "DELETE_ACCOUNT",
            account_list: [],
          };
          let ans = (
            await httpClient.post("wp_plugin/maintenance/account", payload)
          ).data;
          if (ans?.status == "error") {
            showError(ans.message);
          } else if (ans?.status == "success") {
            showSuccess("Gửi yêu cầ u xóa tài khoản thành công");
          }
        } catch (e) {
          console.log(e);
          showError("Có lỗi xẩy ra");
        }
        unMask();
        ctx.emit("close", true);
      }
    };

    const handleFinish = async (values) => {
      ctx.emit("close", true);
    };
    const showWarning = (mesage) => {
      notification.warn({
        message: "Warning",
        description: mesage,
      });
    };
    return {
      handleFinish,
      textValue,
      deleteAccounts,
    };
  },
});
</script>

<style scoped lang="scss">
.delete-account-form-container {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  flex-direction: column;
}

.from-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

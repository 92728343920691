<template>
  <a-menu
    theme="dark"
    v-model:selectedKeys="selectedKeys"
    v-model:openKeys="openKeys"
    mode="inline"
  >
    <div style="height: 64px"></div>
    <div v-if="true">
      <a-sub-menu key="sub1">
        <template #title>
          <span>
            <laptop-outlined />
            Hệ thống
          </span>
        </template>

        <router-link :to="{ name: 'WebsiteList' }">
          <a-menu-item key="1">Chỉ số google</a-menu-item>
        </router-link>
        <router-link :to="{ name: 'InternalCommunications' }">
          <a-menu-item key="2">Truyền thông nội bộ</a-menu-item>
        </router-link>
        <router-link :to="{ name: 'Employee' }">
          <a-menu-item key="3">Nhân sự</a-menu-item>
        </router-link>
        <router-link :to="{ name: 'Wordpress' }">
          <a-menu-item key="4">Wordpress</a-menu-item>
        </router-link>
        <router-link :to="{ name: 'Tracking' }">
          <a-menu-item key="5">Tracking</a-menu-item>
        </router-link>
      </a-sub-menu>
    </div>
  </a-menu>
</template>

<script>
import { parseJwt } from "@/utils.js";
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
  LaptopOutlined,
} from "@ant-design/icons-vue";
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
  computed,
} from "vue";
export default defineComponent({
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
    LaptopOutlined,
  },
  setup() {
    return {
      selectedKeys: ref([]),
      openKeys: ref(["sub1"]),
    };
  },
});
</script>

<template>
  <div class="shadow-background">
    <div id="login-form-wrap">
      <h2>Login</h2>
      <div id="login-form">
        <p>
          <input v-model="username" type="text" id="username" name="username" placeholder="Nhâp tên tài khoản"
            required /><i class="validation"><span></span><span></span></i>
        </p>
        <p>
          <input v-model="password" type="password" id="email" name="email" placeholder="Nhập mật khẩu" required /><i
            class="validation"><span></span><span></span></i>
        </p>
        <p>
          <button id="login" value="Login" @click="login">Đăng nhập</button>
        </p>
      </div>
      <!--create-account-wrap-->
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/apis/httpclient";
import { mask, unMask, parseJwt } from "@/utils";

export default {
  data() {
    return {
      username: null,
      password: null,
    };
  },
  async created() {
    //Nếu đã đăng nhập thì quay về root routerhe
    let token = localStorage.getItem("token");
    if (token) {
      try {
        await httpClient.post("auth/authen", {
          dependOn: null,
          googleToken: null,
        });
        this.$router.push({ name: "Dashboard" });
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async login() {
      mask();
      let data = {
        username: this.username,
        password: this.password,
      };
      try {
        let response = await httpClient.post("auth/login", data);
        localStorage.setItem("token", response.data.token);
        this.$router.push("/");
        this.$router.go();
        // this.$router.go()
      } catch (e) {
        //tài khoản chưa đăng ký
        console.log(e);
        if (e?.response?.status == 404) {
          alert("Tài khoản chưa đăng ký");
        }
      }

      unMask();
    },
  },
};
</script>

<style scoped lang="scss">
.shadow-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}
</style>

<style scoped>
h2 {
  font-weight: 300;
  text-align: center;
}

p {
  position: relative;
}

a,
a:link,
a:visited,
a:active {
  color: #3ca9e2;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:focus,
a:hover,
a:link:focus,
a:link:hover,
a:visited:focus,
a:visited:hover,
a:active:focus,
a:active:hover {
  color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#login-form-wrap {
  background-color: #fff;
  width: 35%;
  margin: 30px auto;
  text-align: center;
  padding: 20px 0 0 0;
  border-radius: 4px;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
}

#login-form {
  padding: 0 60px;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 0 0 0 10px;
  margin: 0;
  color: #8a8b8e;
  border: 1px solid #c2c0ca;
  font-style: normal;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  background: none;
}

input[type="text"]:focus,
input[type="email"]:focus {
  border-color: #3ca9e2;
}

input[type="text"]:focus:invalid,
input[type="email"]:focus:invalid {
  color: #cc1e2b;
  border-color: #cc1e2b;
}

input[type="text"]:valid~.validation,
input[type="email"]:valid~.validation {
  display: block;
  border-color: #0c0;
}

input[type="text"]:valid~.validation span,
input[type="email"]:valid~.validation span {
  background: #0c0;
  position: absolute;
  border-radius: 6px;
}

input[type="text"]:valid~.validation span:first-child,
input[type="email"]:valid~.validation span:first-child {
  top: 30px;
  left: 14px;
  width: 20px;
  height: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="text"]:valid~.validation span:last-child,
input[type="email"]:valid~.validation span:last-child {
  top: 35px;
  left: 8px;
  width: 11px;
  height: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.validation {
  display: none;
  position: absolute;
  content: " ";
  height: 60px;
  width: 30px;
  right: 15px;
  top: 0px;
}

button {
  display: block;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  border: none;
  display: block;
  background-color: #3ca9e2;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 18px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

input[type="submit"]:hover {
  background-color: #329dd5;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#create-account-wrap {
  background-color: #eeedf1;
  color: #8a8b8e;
  font-size: 14px;
  width: 100%;
  padding: 10px 0;
  border-radius: 0 0 4px 4px;
}
</style>

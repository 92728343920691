<template>
  <a-upload-dragger v-if="reload" name="file" :multiple="true" :action="upload_url" :headers="authHeaders"
    :default-file-list="fileList" @change="handleChange">
    <p class="ant-upload-drag-icon">
      <a-icon type="inbox" />
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
    <p class="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibit from uploading
      company data or other band files
    </p>
  </a-upload-dragger>
  <div class="action">
    <a-button type="primary" @click="handleInstall">Cài đặt</a-button>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Input as AInputTextArea,
} from "ant-design-vue";
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from "ant-design-vue";
export default defineComponent({
  components: {
    AForm: Form,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ADatePicker: DatePicker,
    AInputTextArea,
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const textValue = ref();
    const fileList = ref();
    fileList.value = [
      {
        uid: "-1",
        name: "xxx.png",
      },
    ];
    const multiInstall = async () => {
      if (!textValue.value) {
        showWarning("Mời nhập tên plugin cần xóa");
      } else {
        ctx.emit("multiInstall", textValue.value);
      }
    };
    const handleFinish = async (values) => {
      ctx.emit("close", true);
    };
    const handleInstall = async () => {
      mask();
      if (fileList.value.length == 0) {
        showWarning("Vui lòng tải lên ít nhất một file plugin để cài đặt");
      } else {
        const plugins = fileList.value.map((x) => {
          return x.name;
        });
        const website_list = props.selectedRows.map((x) => {
          return x.id;
        });
        const ans = (
          await httpClient.post(
            `wp_plugin/maintenance/plugins?action=INSTALL`,
            {
              plugins: plugins,
              website_list: website_list,
            }
          )
        ).data;
        if (ans?.status == "error") {
          showError(ans.message);
        } else if (ans?.status == "success") {
          showError("Yêu cầu đã được tiếp nhận");
        }
        handleFinish();
      }
      unMask();
    };
    const handleChange = async (info) => {
      const status = info.file.status;
      if (status !== "uploading") {
        fileList.value = info.fileList;
        if (info.file.status == "removed") {
          let name = info.file.name;
          await httpClient.delete(`${upload_url.value}/${name}`);
        }
      }
      if (status === "done") {
        showSuccess(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        showError(`${info.file.name} file upload failed.`);
      }
    };
    const showSuccess = (mesage) => {
      notification.success({
        message: "Success",
        description: mesage,
      });
    };
    const showError = (mesage) => {
      notification.error({
        message: "Error",
        description: mesage,
      });
    };
    const showWarning = (mesage) => {
      notification.warn({
        message: "Warning",
        description: mesage,
      });
    };

    const generateUUID = () => {
      let d = new Date().getTime(); // Timestamp
      let d2 =
        (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported

      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          let r = Math.random() * 16; // Random number between 0 and 16
          if (d > 0) {
            // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    };
    const upload_url = ref(`${window.__config.host_base}files`);
    const token = localStorage.getItem("token");
    const authHeaders = ref({
      Authorization: `Bearer ${token}`,
    });
    const init = async () => {
      reload.value = false;
      mask();
      try {
        let ans = (await httpClient.get(upload_url.value)).data.map((x) => {
          return {
            name: x,
            uid: generateUUID(),
          };
        });
        fileList.value = ans;
      } catch (err) {
        console.log(err);
      }
      reload.value = true;
      unMask();
    };
    const reload = ref(false);
    init();
    return {
      handleFinish,
      textValue,
      multiInstall,
      handleChange,
      showSuccess,
      showWarning,
      handleInstall,
      fileList,
      upload_url,
      authHeaders,
      reload,
    };
  },
});
</script>

<style scoped lang="scss">
.add-website-form-container {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.from-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}
</style>

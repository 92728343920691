<template>
  <body class="shadow-background">
    <div class="document-root show-error">
      <div class="error">
        <div>
          <img
            src="https://www.gstatic.com/images/branding/googleg/1x/googleg_standard_color_48dp.png"
            alt="Biểu trưng Google"
            class="icon"
          />
        </div>
        <div class="title-text">Đăng nhập vào Tài khoản Google</div>

        <div class="subtitle-text" v-if="action == 'NEED_LOGIN_GOOGLE'">
          Bạn phải đăng nhập để truy cập nội dung này
        </div>
        <div class="subtitle-text" v-else>
          Tài khoản không có quyền truy cập vào nội dung, vui lòng liên hệ vớ
          quản trị hệ thống của bạn hoặc đang nhập tài khoản khác.
        </div>
        <div class="button-container">
          <a
            v-if="action == 'NEED_LOGIN_GOOGLE'"
            id="login_btn"
            target="cookieAccessHelp"
            class="primary-button button"
            >Đăng nhập</a
          >
          <a
            v-else
            id="login_btn"
            target="cookieAccessHelp"
            class="primary-button button"
            >Thử lại</a
          >
        </div>
      </div>
    </div>
  </body>
</template>
<script>
export default {
  created() {
    this.action == this.$route.query.action;
  },
  data() {
    return {
      action: null,
    };
  },
  mounted() {
    document.getElementById("login_btn").onclick = function () {
      window.location.href = `${window.__config.host_base}auth/google-login`;
    };
  },
};
</script>

<style nonce="">
.shadow-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
}
body {
  height: 100%;
  margin: 0;
  width: 100%;
}

@media (max-height: 350px) {
  .button {
    font-size: 10px;
  }

  .button-container {
    margin-top: 16px;
  }

  .button.primary-button,
  .button.primary-button:active,
  .button.primary-button:focus,
  .button.primary-button:hover {
    padding: 4px 12px;
  }

  .title-text {
    font-size: 22px;
    line-height: 24px;
  }

  .subtitle-text {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-height: 350px) {
  .button {
    font-size: 14px;
  }

  .button-container {
    margin-top: 16px;
  }

  .button.primary-button,
  .button.primary-button:active,
  .button.primary-button:focus,
  .button.primary-button:hover {
    padding: 12px 24px;
  }

  .title-text {
    font-size: 28px;
    line-height: 36px;
  }

  .subtitle-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.document-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  inset: 0;
  position: absolute;
}

.error,
.login,
.request-storage-access {
  display: none;
}

.error,
.login,
.request-storage-access,
.too-many-login-redirects {
  margin: auto;
  padding: 36px;
}

.document-root.show-error .error,
.document-root.show-login-page .login,
.document-root.show-storage-access .request-storage-access,
.too-many-login-redirects {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button {
  border: none;
  cursor: pointer;
  color: #0b57d0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: Google Sans Text, Roboto, sans-serif;
  border-radius: 100px;
  padding: 12px;
  margin: 0 8px;
  text-decoration: none;
}

.button:hover {
  background-color: rgba(11, 87, 208, 0.078);
}

.button:active,
.button:focus {
  background-color: rgba(11, 87, 208, 0.122);
}

.button.primary-button,
.button.primary-button:active,
.button.primary-button:focus,
.button.primary-button:hover {
  background-color: #0b57d0;
  color: #fff;
}

.button.primary-button:hover {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.149),
    0 1px 2px 0 rgba(0, 0, 0, 0.302);
}

.icon {
  height: 48px;
  margin-bottom: 16px;
}

.title-text {
  font-family: Google Sans, Roboto, sans-serif;
  text-align: center;
}

.subtitle-text {
  font-family: Google Sans Text, Roboto, sans-serif;
  margin-top: 16px;
  text-align: center;
}

/*# sourceMappingURL=style.css.map */
</style>

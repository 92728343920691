<template>
    <a-modal width="850px" v-model:visible="modalVisible" title="Xem cấu hình tài khoản nhận truyền thông nội bộ">
        <!-- Custom content slot -->
        <template #default>
            <div class="add-website-container">
                <div style="display: flex; gap: 20px">
                    <div style="width: 50%;">
                        <label for="subject">I'm <span style="color: red;">*</span> </label>
                        <input type="text" id="subject" name="subject" placeholder="I'm" v-model="currentUser.subject"
                            required />

                        <label for="username">Username/ChatId <span style="color: red;">*</span> </label>
                        <input type="text" id="username" name="username" placeholder="Username/ChatId"
                            v-model="currentUser.username" required />

                        <label for="full_name">Họ tên đầy đủ <span style="color: red;">*</span></label>
                        <input type="text" id="full_name" name="full_name" placeholder="Nguyen Van A"
                            v-model="currentUser.full_name" required />

                        <label for="pronounces">Cách xưng hô <span style="color: red;">*</span></label>
                        <input type="text" v-model="currentUser.pronounces" id="pronounces" name="pronounces"
                            placeholder="Cách xưng hô (Anh/Chị ...)" required>

                        <label for="tags">Tags <span style="color: red;">*</span></label>
                        <template v-for="(tag, index) in currentUser.tags" :key="tag">
                            <a-tooltip v-if="tag.length > 20" :title="tag">
                                <a-tag >
                                    {{ `${tag.slice(0, 20)}...` }}
                                </a-tag>
                            </a-tooltip>
                            <a-tag v-else >
                                {{ tag }}
                            </a-tag>
                        </template>
                        <div style="display: flex; align-items: center; justify-content: flex-end;padding-right: 20px;">
                            <button v-if="false" class="add-proecjt-btn" @click="editUser" type="submit">Sửa</button>
                        </div>
                    </div>
                    <div style="width: 380px;">
                        <div
                            style="display: flex; align-items: center; justify-content: space-between; padding-bottom: 12px;">
                            <label for="websites">Websites
                            </label>
                            <a-button v-if="false" @click="() => { isShowFormAddWebsite = true }"> Thêm </a-button>
                        </div>
                        <a-table :pagination="false" :columns="websiteColumns" :data-source="currentUser.websites" :scroll="{y:300}">
                            <template #bodyCell="{ column, text, record }">
                                <template v-if="column.dataIndex == 'action'">
                                    <a-popconfirm title="Bạn có chắc chắn muốn xóa mục này không?"
                                        @confirm="handleDelete(record)" okText="Yes" cancelText="No">
                                        <a-button type="link" danger>Delete</a-button>
                                    </a-popconfirm>
                                </template>
                                <template v-if="column.dataIndex === 'tags'">
                                    <a-tag v-for="(item, index) in record.tags" :key="index">
                                        {{ item }}
                                    </a-tag>
                                </template>
                            </template>
                        </a-table>
                    </div>
                </div>

            </div>
        </template>
        <!-- No footer slot -->
        <template #footer>
            <!-- You can leave this empty to remove the footer -->
        </template>
    </a-modal>
</template>
<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: () => { }
        },
        cboTag: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        modalVisible: {
            get() {
                return this.isShow;
            },
            set(value) {
                this.$emit('update:isShow', value);
            }
        },
        currentUser: {
            get() {
                var ans = JSON.parse(JSON.stringify(this.user))
                ans['websites'] = ans['websites'].map(x => {
                    return {
                        ...x,
                        'tags': JSON.parse(x['tags'])
                    }
                })
                return ans;
            },
        },
    },
    data() {
        return {
            url: null,
            webSiteTags: [],
            websiteColumns: [
                {
                    title: "Url",
                    dataIndex: "url",
                    key: "url",
                },
                {
                    title: "Tags",
                    dataIndex: "tags",
                    key: "tags",
                }
                
            ]
        }
    },

   
}
</script>


<style lang="scss" scoped>
.add-website-container {
    width: 800px;
    padding: 0px 10px 0px 0px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;

}

textarea {
    width: calc(100% - 20px);
    height: 200px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
}

.add-proecjt-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.add-proecjt-btn:hover {
    background-color: #45a049;
}
</style>

<template>
    <a-modal v-model:visible="isShowFormAddWebsite" title="Thêm cấu hình website">
        <div>
            <label for="url">Url <span style="color: red;">*</span> </label>
            <input type="text" id="url" name="url" placeholder="https://abc.com" v-model="url" required />
            <label for="tags">Tags <span style="color: red;">*</span></label>
            <template v-for="(tag, index) in webSiteTags" :key="tag">
                <a-tooltip v-if="tag.length > 20" :title="tag">
                    <a-tag :closable="true" @close="handleDeleteTags(webSiteTags, index)">
                        {{ `${tag.slice(0, 20)}...` }}
                    </a-tag>
                </a-tooltip>
                <a-tag v-else :closable="true" @close="handleDeleteTags(webSiteTags, index)">
                    {{ tag }}
                </a-tag>
            </template>
            <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                    <a-tag style="background: #fff; cursor: pointer; border-style: dashed">
                        <plus-outlined />
                        Add Tag
                    </a-tag>
                </a>
                <template #overlay>
                    <a-menu>
                        <a-menu-item v-for="(value, index) in cboTag" :key="index">
                            <a href="javascript:;" @click="handleAddTags(webSiteTags, value)">{{ value
                                }}</a>
                        </a-menu-item>

                    </a-menu>
                </template>
            </a-dropdown>

        </div>
        <div style="display: flex; align-items: center; justify-content: flex-end;padding-right: 20px;">
            <a-button class="add-website-btn" @click="addWebsite">Lưu</a-button>
        </div>
    </a-modal>
    <a-modal width="850px" v-model:visible="modalVisible" title="Thêm cấu hình tài khoản nhận truyền thông nội bộ">
        <!-- Custom content slot -->
        <template #default>
            <div class="add-website-container">
                <div style="display: flex; gap: 20px">
                    <div style="width: 50%;">
                        <label for="subject">I'm <span style="color: red;">*</span> </label>
                        <input type="text" id="subject" name="subject" placeholder="I'm" v-model="subject" required />

                        <label for="username">Username/ChatId <span style="color: red;">*</span> </label>
                        <input type="text" id="username" name="username" placeholder="Username/ChatId"
                            v-model="username" required />

                        <label for="full_name">Họ tên đầy đủ <span style="color: red;">*</span></label>
                        <input type="text" id="full_name" name="full_name" placeholder="Nguyen Van A" v-model="fullname"
                            required />

                        <label for="pronounces">Cách xưng hô <span style="color: red;">*</span></label>
                        <input type="text" v-model="pronounces" id="pronounces" name="pronounces"
                            placeholder="Cách xưng hô (Anh/Chị ...)" required>

                        <label for="tags">Tags <span style="color: red;">*</span></label>
                        <template v-for="(tag, index) in tags" :key="tag">
                            <a-tooltip v-if="tag.length > 20" :title="tag">
                                <a-tag :closable="true" @close="handleDeleteTags(tags, index)">
                                    {{ `${tag.slice(0, 20)}...` }}
                                </a-tag>
                            </a-tooltip>
                            <a-tag v-else :closable="true" @close="handleDeleteTags(tags, index)">
                                {{ tag }}
                            </a-tag>
                        </template>
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click.prevent>
                                <a-tag 
                                    style="background: #fff; cursor: pointer; border-style: dashed">
                                    <plus-outlined />
                                    Add Tag
                                </a-tag>
                            </a>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item v-for="(value, index) in cboTag" :key="index">
                                        <a href="javascript:;" @click="handleAddTags(tags, value)">{{ value
                                            }}</a>
                                    </a-menu-item>

                                </a-menu>
                            </template>
                        </a-dropdown>
                    </div>
                    <div style="width: 380px;">
                        <div
                            style="display: flex; align-items: center; justify-content: space-between; padding-bottom: 12px;">
                            <label for="websites">Websites
                            </label>
                            <a-button @click="() => { isShowFormAddWebsite = true }"> Thêm </a-button>
                        </div>
                        <a-table :pagination="false" :columns="websiteColumns" :data-source="websites" :scroll="{y:300}">
                            <template #bodyCell="{ column, text, record }">
                                <template v-if="column.dataIndex == 'action'">
                                    <a-button @click="handleDelete(record)" type="link" danger>Delete</a-button>
                                </template>
                                <template v-if="column.dataIndex === 'tags'">
                                    <a-tag v-for="(item, index ) in record.tags" :key="index">
                                        {{ item }}
                                    </a-tag>
                                </template>
                            </template>
                        </a-table>
                    </div>
                </div>

                <div style="display: flex; align-items: center; justify-content: flex-end;padding-right: 20px;">
                    <button class="add-proecjt-btn" @click="addUser" type="submit">Lưu</button>
                </div>
            </div>
        </template>

        <!-- No footer slot -->
        <template #footer>
            <!-- You can leave this empty to remove the footer -->
        </template>
    </a-modal>
</template>

<script>
import { mask, unMask, showSuccess, showError, showWarning } from "@/utils";
import { httpClient } from "@/apis/httpclient";
export default {
    props: {
        isShow: {
            type: Boolean,
            default: true,
        },
        cboTag: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        modalVisible: {
            get() {
                return this.isShow;
            },
            set(value) {
                this.$emit('update:isShow', value);
            }
        }
    },
    data() {

        return {
            isShowFormAddWebsite: false,
            webSiteTags: [],
            url: null,
            tags: [],
            subject: null,
            username: null,
            fullname: null,
            pronounces: null,
            websites: [],
            websiteColumns: [
                {
                    title: "Url",
                    dataIndex: "url",
                    key: "url",
                },
                {
                    title: "Tags",
                    dataIndex: "tags",
                    key: "tags",
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action'
                }
            ]
        }
    },
    methods: {
        isValidUrl(url) {
            const regex = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/i;
            return regex.test(url);
        },
        async addWebsite() {
            if (this.webSiteTags.length == 0 || !this.url) {
                showWarning('Vui lòng điền đầy đủ thông tin')
                return
            }
            if(!this.isValidUrl(this.url)){
                showWarning('Vui lòng điền đúng định dạng Url')
                return
            }
            mask()
            let exits = (await httpClient.get(`alert/website/exits?url=${this.url}`)).data
            unMask()
            if(exits==true){
                showWarning(`${this.url} đã đuợc phụ trách`)
                return
            }
            this.websites.push({ 'url': this.url, 'tags': this.webSiteTags , 'username': this.username});
            this.websites = JSON.parse(JSON.stringify(this.websites));
            this.isShowFormAddWebsite = false
            this.url = null
            this.webSiteTags = []
        },
        async handleDelete(row) {
            mask()
            this.websites = JSON.parse(JSON.stringify(this.websites.filter(x=>x.url!=row.url)))  
            unMask();
        },

        handleDeleteTags(tags, index) {
            tags.splice(index, 1)
        },
        handleAddTags(tags, value) {
            if (tags.includes(value)) {
                showWarning(`Tag ${value} đã tồn tại`);
                return;
            }
            tags?.push(value);
        },
        async addUser() {
            mask()
            const me = this;
            if (!me.fullname || !me.pronounces || !me.username || !me.subject || !me.tags.length) {
                showWarning('Vui lòng điền đầy đủ thông tin')
                unMask()
                return;
            }
            let ans = await httpClient.post(`alert`, {
                "username": me.username,
                "full_name": me.fullname,
                "pronounces": me.pronounces,
                "subject": me.subject,
                "tags": JSON.stringify(me.tags),
                "websites":JSON.stringify(me.websites)
            })
            if (ans.data) {
                showSuccess('Thêm thành công')
            } else {
                showError('Thêm thất bại')
            }
            this.username = null;
            this.fullname = null;
            this.pronounces = null;
            this.modalVisible = false;
            this.subject = null;
            this.tags = []
            this.websites = [];
            this.$emit('onAdd');
            setTimeout(()=>{
                unMask()
            },1000)
        },
    }

}
</script>

<style lang="scss" scoped>
.add-website-container {
    width: 800px;
    padding: 0px 10px 0px 0px;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;

}

textarea {
    width: calc(100% - 20px);
    height: 200px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
}

.add-proecjt-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.add-proecjt-btn:hover {
    background-color: #45a049;
}
</style>
<template>
  <div class="dashboard-container">
    <div class="page-title">Google Ranking Dashboard</div>
    <ranking-chart></ranking-chart>
    <div id="setting">
      <a-collapse>
        <a-collapse-panel key="1" header="Danh sách các file cấu hình">
          <p v-for="item in data" :key="item">
            <a :href="item">{{ item }}</a>
          </p>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import RankingChart from "@/views/dashboard/chart/RankingChart.vue";
import { httpClient } from "@/apis/httpclient";
export default {
  components: {
    RankingChart,
  },
  async created() {
    const me = this;
    me.data = Object.values((await httpClient.get("/config/list")).data).map(
      (item) =>
        `https://docs.google.com/spreadsheets/d/${item}/edit?gid=0#gid=0`
    );
  },
  data() {
    return {
      data: null,
    };
  },
};
</script>

<style>
.dashboard-container {
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.page-title {
  padding: 16px;
  font-size: 24px;
  font-weight: 600;
}
#setting {
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
</style>

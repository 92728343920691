<template>
  <a-form layout="vertical" @finish="handleFinish" class="wordpress-form">
    <div class="multi-remove-form-container">
      <a-textarea
        v-if="!deleteAll"
        v-model:value="textValue"
        :rows="8"
        :disabled="deleteAll"
        placeholder="Nhập tên plugin, mỗi plugin nằm trên một dòng"
      />
      <div style="display: flex; justify-content: flex-start; gap: 12px">
        <a-checkbox v-model:checked="deleteAll" style="width: fit-content" />
        <div>Xóa tất cả</div>
      </div>
    </div>

    <div class="from-action">
      <a-button type="primary" html-type="submit" @click="multiDelete"
        >Xóa</a-button
      >
    </div>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Input as AInputTextArea,
} from "ant-design-vue";
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from "ant-design-vue";
export default defineComponent({
  components: {
    AForm: Form,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ADatePicker: DatePicker,
    AInputTextArea,
  },
  setup(props, ctx) {
    const textValue = ref();

    const multiDelete = async () => {
      if (deleteAll.value) {
        textValue.value = "#ALL#";
      }
      if (!textValue.value) {
        showWarning("Mời nhập tên plugin cần xóa");
      } else {
        ctx.emit("multiRemove", textValue.value);
      }
    };

    const handleFinish = async (values) => {
      ctx.emit("close", true);
    };
    const showWarning = (mesage) => {
      notification.warn({
        message: "Warning",
        description: mesage,
      });
    };
    const deleteAll = ref(false);
    return {
      handleFinish,
      textValue,
      multiDelete,
      deleteAll,
    };
  },
});
</script>

<style scoped lang="scss">
.multi-remove-form-container {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  flex-direction: column;
}
.from-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

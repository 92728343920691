<template>
  <a-form
    :form="form"
    layout="vertical"
    @finish="handleFinish"
    class="wordpress-form"
  >
    <div class="add-website-form-container">
      <div class="block">
        <a-form-item label="Trang quản trị" name="admin_url">
          <a-input v-model:value="formData.admin_url" />
        </a-form-item>

        <a-form-item label="Brand" name="brand">
          <a-input v-model:value="formData.brand" />
        </a-form-item>
      </div>

      <div class="block">
        <a-form-item label="Tên đăng nhâp" name="username">
          <a-input v-model:value="formData.username" />
        </a-form-item>
        <a-form-item label="Mật khẩu" name="password">
          <a-input v-model:value="formData.password" />
        </a-form-item>
      </div>
    </div>
    <div class="from-action">
      <a-form-item>
        <a-button type="primary" html-type="submit" @click="handleFinish"
          >Thêm</a-button
        >
      </a-form-item>
    </div>
  </a-form>
</template>
<script>
import { defineComponent, reactive } from "vue";
import {
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Input as AInputTextArea,
} from "ant-design-vue";
import { httpClient } from "@/apis/httpclient";
import { mask, unMask } from "@/utils";
import { notification } from "ant-design-vue";
export default defineComponent({
  components: {
    AForm: Form,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ADatePicker: DatePicker,
    AInputTextArea,
  },
  setup(props, ctx) {
    // Define emitted events
    const formData = reactive({
      admin_url: "",
      brand: "",
      username: "",
      password: "",
    });

    const handleFinish = async (values) => {
      console.log(formData);
      let ans = await add();
      ctx.emit("close", true);
      ctx.emit("refresh");
    };
    const add = async () => {
      mask();
      let ans = await httpClient.post(`wp_plugin/websites`, {
        admin_url: formData.admin_url,
        brand: formData.brand,
        username: formData.username,
        password: formData.password,
      });
      if (ans) {
        showSuccess("Thêm thành công");
      } else {
        showError("Thêm thất bại");
      }
      unMask();
    };
    const showSuccess = (mesage) => {
      notification.success({
        message: "Success",
        description: mesage,
      });
    };
    const showError = (mesage) => {
      notification.error({
        message: "Error",
        description: mesage,
      });
    };
    return {
      formData,
      handleFinish,
      add,
    };
  },
});
</script>

<style scoped lang="scss">
.add-website-form-container {
  display: flex;
  gap: 12px;

  .block {
    width: 50%;
  }
}
.from-action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

export function mask() {
  document.querySelector("#app-loading").style.visibility = "visible";
}
export function unMask() {
  document.querySelector("#app-loading").style.visibility = "hidden";
}

export function parseJwt() {
  try {
    let token = localStorage.getItem("token");
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    // console.log(e);
  }
}
import { message, notification } from "ant-design-vue";
export function showSuccess(message) {
  notification.success({
    message: "Success",
    description: message,
  });
}
export function showError(message) {
  notification.error({
    message: "Error",
    description: message,
  });
}
export function showWarning(message) {
  notification.warning({
    message: "Warning",
    description: message,
  });
}

export function getCookie(name) {
  // Create a regular expression to find the cookie by name
  let regex = new RegExp("(?:^|; )" + encodeURIComponent(name) + "=([^;]*)");
  let match = document.cookie.match(regex);

  // If the cookie is found, return its value, otherwise return null
  return match ? decodeURIComponent(match[1]) : null;
}
export function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}

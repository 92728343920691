<template>
  <div class="chang_password_form_container">
    <a-switch checked-children="Tất cả" un-checked-children="Tùy chọn" v-model:checked="changeAll" />
    <div class="content">
      <div v-if="changeAll" class="c_message">
        Thay toàn bộ tài khoản có trong website
      </div>
      <div v-else>
        <a-table :columns="columns" :dataSource="data" :pagination="false" rowKey="key">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'action'">
              <div style="display: flex; gap: 8px">
                <a v-if="data.length > 1" @click="deleteRecord(index)" style="color: red">Xóa</a>
                <a @click="addRecord" v-if="index == data.length - 1">Thêm </a>
              </div>
            </template>
            <template v-else>
              <a-input v-if="index == data.length - 1" v-model:value="record[column.dataIndex]" />
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <div class="action">
      <a-button type="primary" @click="changePassword">Đổi</a-button>
    </div>
  </div>
</template>
<script>
import { showWarning, mask, unMask, showError, showSuccess } from "@/utils";
import { httpClient } from "@/apis/httpclient";
export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      changeAll: false,
      data: [{ username: "", password: "" }],
      columns: [
        {
          title: "Tên đăng nhập",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "Mật khẩu",
          dataIndex: "password",
          key: "password",
        },
        {
          title: "",
          dataIndex: "action",
          key: "action",
        },
      ],
    };
  },
  methods: {
    deleteRecord(index) {
      this.data.splice(index, 1);
    },
    validate() {
      let isNull = false;
      for (let i = 0; i < this.data.length; i++) {
        if (!this.data[i].username || !this.data[i].password) {
          isNull = true;
          break;
        }
      }
      return isNull;
    },
    addRecord() {
      const isNull = this.validate();
      if (isNull) {
        showWarning("Tên đăng nhập và mật khẩu không được để trống");
        return;
      }
      this.data.push({
        username: "",
        password: "",
      });
    },
    async changePassword() {
      const isNull = this.validate();
      if (isNull && !this.changeAll) {
        showWarning("Tên đăng nhập và mật khẩu không được để trống");
        return;
      }

      mask();
      try {
        const website_list = this.selectedRows.map((x) => {
          return x.id;
        });
        const payload = {
          website_list: website_list,
          account_list: this.changeAll ? [] : this.data,
          action: "CHANGE_PASS",
        };
        let ans = (
          await httpClient.post("wp_plugin/maintenance/account", payload)
        ).data;
        if (ans?.status == "error") {
          showError(ans.message);
        } else if (ans?.status == "success") {
          showSuccess(
            "Gửi yêu cầ đổi mật khẩu thành công. File danh sách tài khoản sẽ đươc gửi qua telegram sau khi đổi hoàn tất"
          );
          alert(
            "Gửi yêu cầ đổi mật khẩu thành công. File danh sách tài khoản sẽ đươc gửi qua telegram sau khi đổi hoàn tất"
          );
        }
      } catch {
        showError("Có lỗi xẩy ra");
      }
      unMask();
      this.$emit("close", true);
    },
  },
};
</script>

<style lang="scss">
.chang_password_form_container {
  .c_message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }

  .content {
    margin-bottom: 24px;
  }

  .action {
    display: flex;
    align-self: center;
    justify-content: flex-end;
  }
}
</style>
